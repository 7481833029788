<template>
	<div>
	</div>
</template>
<style scoped>
</style>

<script>
	import AppToQr from '../js/AppToQr';
	export default {
		...AppToQr
	}
</script>
